<template>
  <v-container>
    <v-row class="mb-4">
      <h2>Feedback to Health and Coping Post - Part 2</h2>
    </v-row>
    <v-form v-for="question in questions" :key="question.inputName">
      <div class="mb-4">Example Student Post:</div>
      <p class="mb-8 mt-8">"{{ examplePosts[0].text }}"</p>
      <v-row class="mb-4 mt-4">
        {{ question.text }}
      </v-row>
      <div>
        <s-textarea v-model="inputs[question.inputName]" class="mb-4" outlined />
      </div>
    </v-form>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'UPEIPsych1020A8Task3Part2',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        feedbackAnswer: null,
      },
      questions: [
        {
          text: 'Please provide appropriate feedback to this example post made by a student about Health and Coping.',
          inputName: 'feedbackAnswer',
        },
      ],
      examplePosts: [
        {
          postNumber: 0,
          text: 'The videos this week were really quite interesting to me and I did really enjoy watching them. I personally connected to one more than the others and that was the second one. This video spoke on how unhealthy it is to be constantly thinking about stressful things such as work outside of the designated work time. He spoke on how he tracked the hours that he thought about work during the day and it was a lot of time. I can personally relate to this as I fell as though I am constantly stressing over things like school work or other similar things. It causes a burden when I am trying to be engaged in something fun but all I can think about is an assignment due or a test in a few days. This is why I love the summer time so much, I can finally relax and not feel so tense all of the time. There will probably be stress in my entire life, it does not just end when you graduate university. There is work, kids, bills, and finances always weighing on you, so there must be a better way to manage this stuff. Maybe keeping track of the hours I spend over thinking will help open my eyes to how ridiculous it is. I also found the other two videos quite interesting in providing ways to deal with certain situations in life that we may not have the solution to. They were quite helpful.  It is important to be prepared for these things even if you are not experiencing them right at the moment, you never know what the future has in store for you. Overall, these three videos are important to keep in mind when dealing with both your own and also others feelings as you never know what someone is going through.',
        },
      ],
    };
  },
};
</script>
